import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import { v4, validate } from 'uuid';
import cs from 'crypto-random-string';

export type ResultStatus = 'approved' | 'expired' | 'expiring'
export type ResultType = {
	status: boolean,
	statusDetailed: ResultStatus | null,
	expiring_soon: boolean,
	number: string,
	filename: string,
	created_at: number,
	expired_ts: number
}

type ManualCheckType = {
	status: boolean,
	not_found: boolean,
	expiring_soon: boolean,
	expiration_date: number
}

export const NotFoundResult: ResultType = {
	status: false,
	statusDetailed: null,
	expiring_soon: false,
	number: '',
	filename: '',
	created_at: 0,
	expired_ts: 0,
};

export const uploadFile = (file: Blob): Promise<{
	task: firebase.storage.UploadTask,
	name: string
}> => new Promise((resolve, reject) => {

	try {

		const name = `to_parse_${v4()}.${file.type.split('/')[1]}`;

		const task = firebase.storage().ref()
			.child(`checklit/scans/${name}`)
			.put(file);

		resolve({
			task,
			name,
		});

	} catch (e) {

		reject(e);

	}

});

export const checkResult = (name: string): Promise<
	ResultType
> => new Promise(async (resolve, reject) => {

	try {

		const results = await firebase.firestore()
			.collection('results')
			.where('filename', '==', name)
			.get();

		if (results.empty) {
			return void reject(`File ${name} is not found`);
		}

		const data: ResultType = {
			...NotFoundResult
		};

		results.forEach((row) => {

			const d = row.data();

			for (const i in d) {
				data[i] = d[i];
			}

		});

		const { status, expiring_soon, number } = data;

		if (status && !expiring_soon) {
			data.statusDetailed = 'approved';
		}

		if (!status && number) {
			data.statusDetailed = 'expired';
		}

		if (status && expiring_soon) {
			data.statusDetailed = 'expiring';
		}

		resolve(data);

	} catch (e) {

		reject(e);

	}

});

export const checkManual = (number: string): Promise<
	ResultType
> => new Promise(async (resolve, reject) => {

	try {

		const checkCode = firebase.functions()
			.httpsCallable('checkCode');

		const { data } = await checkCode({ number });

		const result: ResultType = {
			...NotFoundResult
		};

		const {
			status,
			expiring_soon,
			not_found,
			expiration_date
		} = data as unknown as ManualCheckType;

		result.expired_ts = expiration_date;

		if (status && !expiring_soon) {
			result.statusDetailed = 'approved';
		}

		if (!status && !not_found) {
			result.statusDetailed = 'expired';
		}

		if (status && expiring_soon) {
			result.statusDetailed = 'expiring';
		}

		resolve(result);

	} catch (e) {

		reject(e);

	}

});

export const isPWA = (): boolean => {

	return window.matchMedia('(display-mode: standalone)').matches;

}

export const getToken = (
	name: string,
	length?: number
): {
	token: string,
	setup: boolean
} => {

	const storage = window.localStorage;
	const response = {
		token: storage.getItem(name) || '',
		setup: false,
	};

	if (!response.token) {

		response.token = cs({
			length: length || 64,
			type: 'url-safe',
		});

		response.setup = true;

		storage.setItem(name, response.token);

	}

	return response;

}

export const getUUID = (): string => {

	const { pathname } = new URL(window.location.href);

	const uuid = pathname.substring(1);

	if (!validate(uuid)) {
		return '';
	}

	return uuid;

}

export const checkUUID = (props: {
	uuid?: string,
	token?: string,
	setup?: boolean
}): Promise<{
	uuid: string,
	status: boolean,
	firstly?: boolean,
	error?: {}
}> => new Promise(async (resolve, reject) => {

	const {
		uuid = getUUID(),
		token,
		setup,
	} = props;

	try {

		if (!uuid) {
			return void reject(`Incorrect UUID to check`);
		}

		const checkUUID = firebase.functions()
			.httpsCallable('checkUUID');

		const req: Parameters<typeof checkUUID>[0] = {
			uuid,
		};

		if (token) {
			req.token = token;
		}

		if (setup !== undefined) {
			req.setup = setup;
		}

		const { data } = await checkUUID(req);

		resolve({
			uuid,
			...data,
		});

	} catch (e) {

		reject(e);

	}

});

export const resendByUUID = (uuid?: string): Promise<{
	status: true
}> => new Promise(async (resolve, reject) => {

	try {

		if (!uuid) {
			uuid = getUUID();
		}

		if (!uuid) {
			return void reject(`Incorrect UUID to check`);
		}

		const resendByUUID = firebase.functions()
			.httpsCallable('resendByUUID');

		const { data } = await resendByUUID({ uuid });

		resolve(data);

	} catch (e) {

		reject(e);

	}

});

export const updateManifest = (uuid?: string) => {

	if (!uuid) {
		uuid = getUUID();
	}

	if (!uuid) {
		return;
	}

	const manifest = {
		short_name: 'CheckLIT',
		name: 'CheckLIT',
		icons: [{
			src: 'logo64x64.png',
			sizes: '64x64 32x32 24x24 16x16',
			type: 'image/png'
		}, {
			src: 'logo192x192.png',
			type: 'image/png',
			sizes: '192x192'
		}, {
			src: 'logo512x512.png',
			type: 'image/png',
			sizes: '512x512'
		}, {
			src: 'logo512x512.png',
			type: 'image/png',
			sizes: '512x512',
			purpose: 'any maskable'
		}],
		start_url: `https://checklit.aimecast.com/${uuid}`,
		display: 'standalone',
		orientation: 'portrait',
		theme_color: '#3dae49',
		background_color: '#ffffff'
	};

	const json = JSON.stringify(manifest);

	const blob = new Blob([ json ], {
		type: 'application/json',
	});

	const url = URL.createObjectURL(blob);
	const tag = document.querySelector('[rel=manifest]');

	if (!tag) {
		return;
	}

	tag.setAttribute('href', url);

}

export const inviteByEmail = (email: string): Promise<{
	status: boolean,
	error?: string
}> => new Promise(async (resolve, reject) => {

	try {

		const inviteByEmail = firebase.functions()
			.httpsCallable('inviteByEmail');

		const { data } = await inviteByEmail({ email });

		resolve(data);

	} catch (e) {

		reject(e);

	}

});

export const sendLog = (method: string, content: object) => {

	const json = JSON.stringify(content, null, ' ');

	fetch('https://discord.com/api/webhooks/786197826988277770/hSclUO4ZNej2tqIBOQciC5EBYPwNudlVAuMhEDkBG-DAXRC80yOU_CNfmeSeuHmpTqWh', {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			content: method + ' ```JSON\n' + json + '```',
		}),
	});

}

export const isIos = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isAndroid = (): boolean => /android/.test(navigator.userAgent.toLowerCase());
