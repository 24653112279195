import React, { useState } from 'react';
import cn from 'classnames';

import { ILinkForm, LinkForm } from './';

export interface IMessage {
	head?: string,
	text?: string | JSX.Element,
	shown?: boolean,
	isError?: boolean,
	action?: false | {
		children: string,
		className?: string,
		onClick?: (e: React.MouseEvent) => void
	},
	form?: ILinkForm,
	closeable?: (e: React.MouseEvent) => void
}

export const Message: React.FC<IMessage> = (props) => {

	const {
		head,
		text,
		shown,
		isError = true,
		action,
		form,
		closeable
	} = props;

	const [ altMsg, setAltMsg ] = useState<{
		text: JSX.Element,
		tone: boolean
	} | null>(null);

	return (
		<div className={cn('message', { shown, 'is-error': isError })}>
			<h2>{head}</h2>
			{text && (
				<p
					className={cn({
						success: altMsg && altMsg.tone,
						failure: altMsg && !altMsg.tone,
					})}
					children={altMsg ? altMsg.text : text} />
			)}
			{action && (
				<button {...action} />
			)}
			{form && (
				<LinkForm
					{...form}
					onSend={() => setAltMsg(null)}
					onFail={(error) => {

						form.onFail(error);

						setAltMsg({
							text: error,
							tone: false,
						});

					}}
					onResult={(email) => {

						form.onResult(email);

						setAltMsg({
							text: <span>The link sent to <b>{email}</b></span>,
							tone: true,
						});

					}} />
			)}
			{closeable && (
				<button
					onClick={closeable}
					children="Close" />
			)}
		</div>
	);

}
