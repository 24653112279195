import React, { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import { CodeForm, ICodeForm } from './';

type Button = DetailedHTMLProps<
	ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>

export const NotFound: React.FC<{
	shown: boolean,
	button?: Pick<Button, 'children' | 'className' | 'onClick'>,
	form?: ICodeForm,
}> = ({ shown, button, form }) => {

	return (
		<div className={cn('not-found', { shown })}>
			<h2>Item not found</h2>
			{button && <button {...button} />}
			{form && (
				<>
					<p>or enter the code manually below</p>
					<CodeForm {...form} />
				</>
			)}
		</div>
	);

}
