import React from 'react';
import cn from 'classnames';

import { Icon } from './';

export const Info: React.FC<{ text: string, noBorder?: boolean }> = ({ text, noBorder }) => {

	return (
		<div className={cn('info', { noBorder })}>
			<Icon name="info" />
			<p dangerouslySetInnerHTML={{ __html: text }}></p>
		</div>
	);

}
