import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';

import { inviteByEmail } from '../service';
import { Icon } from './Icon';

export interface ILinkForm {
	head?: string,
	onSend?: () => void,
	onFail: (e: any) => void,
	onResult: (email: string) => void
}

export const LinkForm: React.FC<ILinkForm> = ({
	head,
	onSend,
	onFail,
	onResult
}) => {

	const input = useRef<HTMLInputElement>(null);

	const [ email, setEmail ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ result, setResult ] = useState(false);

	const onSubmit = useCallback(async (e: React.FormEvent) => {

		e.preventDefault();

		if (!input.current) {
			return;
		}

		if (!email) {
			return void input.current.focus();
		}

		try {

			setLoading(true);

			onSend && onSend();

			const {
				status,
				error
			} = await inviteByEmail(email.trim());

			if (!status) {
				onFail(error);
				return void setEmail('');
			}

			setResult(true);

			onResult(email);

		} catch (e) {

			onFail(e);

		} finally {

			setLoading(false);

		}

	}, [ email, input, onSend, onResult, onFail ]);

	return (
		<div className="link-form">
			{head && <h2>{head}</h2>}
			<form onSubmit={onSubmit}>
				<input
					ref={input}
					disabled={result}
					type="email"
					spellCheck="false"
					placeholder="Enter email"
					aria-label="Email to send link"
					value={email}
					onChange={({ target }) => setEmail(target.value)} />
				<button
					disabled={result}
					className={cn({ loading })}
					aria-label="Email sending button"
					children={<Icon name="check" />} />
			</form>
		</div>
	);

}
