import React from 'react';

import { Icon } from './';

export const Scan = () => {

	return (
		<div className="scan">
			<Icon name="scanBorder" />
			<Icon name="scanBorder" />
			<Icon name="scanBorder" />
			<Icon name="scanBorder" />
		</div>
	);

}
