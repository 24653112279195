import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import {
	Icon,
	Info,
	Scan,
	NotFound,
	Result,
	Message,
	IMessage,
	CodeForm,
	ICodeForm,
	TC
} from './component';

import {
	uploadFile,
	checkResult,
	NotFoundResult,
	ResultType,
	checkUUID,
	isPWA,
	resendByUUID,
	getToken,
	updateManifest,
	isIos,
	isAndroid
} from './service';

import './assets/scss/app.scss';

export const App = () => {

	const camPreview = React.useRef<HTMLVideoElement>(null);

	const [ appConf, setAppConf ] = useState<{
		mode: 'stream' | 'camera',
		timeout: number,
		window?: {
			height: number,
			width: number,
		},
		settings?: MediaTrackSettings,
		retry: {
			limit: number,
			timeout: number
		}
	}>({
		mode: 'stream',
		timeout: 8,
		retry: {
			limit: 5,
			timeout: 2,
		},
	});

	const [ appState, setAppState ] = useState<{
		manual: boolean,
		installable: boolean,
		authenticating: boolean,
		'authenticating-loading': boolean,
		openning: boolean,
		openned: boolean,
		taking: boolean
	}>({
		manual: false,
		installable: false,
		authenticating: false,
		'authenticating-loading': false,
		openning: false,
		openned: false,
		taking: false,
	});

	const [ appStore, setAppStore ] = useState<{
		track?: MediaStreamTrack,
		result?: ResultType,
		message?: IMessage
	}>();

	const error = useCallback((
		head: string,
		text?: string | JSX.Element,
		action?: IMessage['action'] | false,
		isError?: boolean,
		form?: IMessage['form'],
		closeable?: IMessage['closeable']
	) => {

		if (action === undefined) {
			action = {
				children: 'OK',
				onClick: (e) => setAppStore((s) => ({
					...s,
					message: {
						...s?.message,
						shown: false,
					},
				})),
			}
		}

		setAppStore((s) => ({
			...s,
			message: {
				head,
				text,
				shown: true,
				action,
				isError,
				form,
				closeable,
			},
		}));

	}, []);

	useEffect(() => {

		updateManifest();

		setAppState((s) => ({
			...s,
			authenticating: true,
			'authenticating-loading': true,
		}));

		const susMessage = async (resend?: boolean) => {

			if (resend) {
				await resendByUUID();
			}

			setAppState((s) => ({
				...s,
				'authenticating-loading': false,
			}));

			return void error(
				TC.SUS_HEAD,
				<span dangerouslySetInnerHTML={{ __html: TC.SUS_TEXT }} />,
				false
			);

		}

		const check = async () => {

			try {

				const is_pwa = isPWA();

				if (is_pwa) {

					const props = getToken('auth');

					const { status } = await checkUUID(props);

					// if (!status) {
					// 	return susMessage(true);
					// }

					return void setAppState((s) => ({
						...s,
						authenticating: false,
						'authenticating-loading': false,
					}));

				}

				const {
					status,
					firstly
				} = await checkUUID({});

				// if (firstly && status) {
				// 	window.localStorage.removeItem('auth');
				// }

				// if (!firstly) {
				// 	return void susMessage(true);
				// }

				// if (status) {

					const showMan = !window.location.search.includes('guide=0')

					if (showMan && isIos()) {
						error(
							'Install app',
							<span className="share-text">To find the app from your home screen<br />1. Make sure you have latest iOS update<br/> 2. Open the app on Safari<br /> 3. Click on Share menu <Icon name="share" /> <br /> 4. Select "Add to Home Screen"</span>,
							false,
							false,
							undefined,
							(e) => {

								setAppStore((s) => ({
									...s,
									message: {
										...s?.message,
										shown: false,
									},
								}));

							},
						);
					}

					if (showMan && isAndroid()) {
						error(
							'Install app',
							<span className="share-text">To find the app from your home screen<br/>1. open invitation link in Google Chrome<br /> 2. Click on Chrome menu <Icon name="shareChrome" /><br /> 3. Click "Add to Home Screen"</span>,
							false,
							false,
							undefined,
							(e) => {

								setAppStore((s) => ({
									...s,
									message: {
										...s?.message,
										shown: false,
									},
								}));

							},
						);
					}

					return void setAppState((s) => ({
						...s,
						installable: true,
						authenticating: false,
						'authenticating-loading': false,
					}));

				// }

			} catch (e) {

				setAppState((s) => ({
					...s,
					'authenticating-loading': false,
				}));

				error(
					'Authentication Required',
					<span>Enter email to receive the auth link</span>,
					false,
					false,
					{
						onFail: (e) => console.error(e),
						onResult: (email) => console.log(email)
					}
				);

			}

		}

		check();

	}, [ error ]);

	useEffect(() => {

		setAppConf(c => ({
			...c,
			window: {
				height: window.innerHeight,
				width: window.innerWidth,
			},
		}));

	}, []);

	const startScanning = useCallback(async (e: React.MouseEvent) => {

		if (appState.authenticating) {
			return;
		}

		if (!navigator.mediaDevices) {
			return void error(
				'App Error',
				<span>Camera device is not recognized</span>
			);
		}

		if (!appConf?.window) {
			return;
		}

		try {

			setAppState(s => ({ ...s, openning: true, }));

			setAppStore({});

			const width = appConf.window.width * 4;
			const height = appConf.window.height * 4;

			const config = {
				audio: false,
				video: {
					width,
					height,
					aspectRatio: height / width,
					// bug in chrome
					facingMode: {
						exact: 'environment'
					},
				},
			};

			const stream = await navigator.mediaDevices
				.getUserMedia(config);

			const [ track ] = stream.getVideoTracks();

			setAppStore(s => ({ ...s, track }));

			const settings = track.getSettings();

			setAppConf(c => ({ ...c, settings }));

			if (!camPreview.current) {
				throw new Error('Cam preview element is null');
			}

			setAppState(s => ({
				...s,
				openning: false,
				openned: true,
			}));

			camPreview.current.srcObject = stream;

		} catch (e) {

			setAppState(s => ({
				...s,
				openning: false,
				openned: false,
			}));

			error(
				'Camera access is required for scanning',
			);

		}

	}, [ appState, appConf, camPreview, error ]);

	const stopScanning = useCallback((e: React.MouseEvent) => {

		camPreview.current?.pause();

		appStore?.track?.stop();

		setAppState(s => ({
			...s,
			openning: false,
			openned: false,
		}));

	}, [ appStore, camPreview ]);

	const clearResult = useCallback(() => {

		setAppStore((s) => ({
			...s,
			result: undefined,
		}));

	}, []);

	const toggleManual = useCallback(() => {

		setAppState((s) => ({
			...s,
			manual: !s.manual,
		}));

	}, []);

	const invite = useCallback(() => {

		error(
			'Invite by email',
			<span>Enter email to receive the auth link</span>,
			false,
			false,
			{
				onFail: console.error,
				onResult: () => {},
			},
			(e) => {

				setAppStore((s) => ({
					...s,
					message: {
						...s?.message,
						shown: false,
					},
				}));

			},
		);

	}, [ error ]);

	const takeScan = useCallback((e: React.MouseEvent) => {

		if (!appConf?.window || !camPreview.current) {
			return;
		}

		setAppState(s => ({ ...s, taking: true }));

		const canvas = document.createElement('canvas');

		const video = camPreview.current;
		const ratio = video.videoWidth / video.videoHeight;

		const width = video.videoWidth - 100;
		const height = Math.round(width / ratio);

		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext('2d');

		if (!context) {
			return;
		}

		context.drawImage(camPreview.current, 0, 0, canvas.width, canvas.height);

		camPreview.current?.pause();

		appStore?.track?.stop();

		canvas.toBlob(async (file) => {

			if (!file) {
				return;
			}

			video.poster = URL.createObjectURL(file);

			const { name } = await uploadFile(file);

			let tryCount = 0;

			const reCheck = (timeout: number) => {

				setTimeout(async () => {

					tryCount++;

					try {

						const result = await checkResult(name);

						setAppState(s => ({
							...s,
							taking: false,
						}));

						setAppStore(s => ({
							...s,
							result,
						}));

					} catch (e) {

						if (tryCount < appConf.retry.limit) {
							return void reCheck(appConf.retry.timeout);
						}

						setAppState(s => ({
							...s,
							taking: false,
						}));

						setAppStore((s) => ({
							...s,
							result: {
								...NotFoundResult,
							},
						}));

					}

				}, timeout * 1000);

			}

			reCheck(appConf.timeout);

		}, 'image/jpeg', 1);

	}, [ appStore, appConf ]);

	const ManualCodeForm: ICodeForm = {
		onFail: (e) => {},
		onResult: (result) => {

			if (appState.openned) {
				setAppState((s) => ({
					...s,
					manual: true,
					openned: false,
				}));
			}

			setAppStore((s) => ({
				...s,
				result,
			}));

		},
	};

	return (
		<div className={cn('home-screen', { ...appState })}>
			<div className="spin">
				<i></i>
			</div>
			<div className="logo">
				<Icon name="logo" />
			</div>
			{appState.manual ? (
			<div className="scan-manual-wrap">
				<CodeForm
					head="Enter code"
					{...ManualCodeForm} />
			</div>
			) : (
			<div className="scan-trigger-wrap">
				<button
					onClick={startScanning}
					children="Scan now"
					aria-label="Scan start button"
					className="scan-trigger" />
			</div>
			)}
			<div className="footer">
				<div className="no-cam">
					<button
						onClick={toggleManual}
						children={`${appState.manual ? 'Scan' : 'Manually Enter'} Code`} />
					<button
						onClick={invite}
						children="Invite by email"
						aria-label="Invite popup trigger"
						className="invite-trigger" />
				</div>
				<Info
					text={appState.manual ? TC.INFO_MANUAL : TC.INFO_SCAN} />
			</div>
			<div className="cam-preview">
				<button
					onClick={stopScanning}
					aria-label="Scan stop"
					children={<Icon name="circleCross" />}
					className="stop-scan-trigger" />
				<Info
					noBorder
					text={TC.SCAN_INFO} />
				<Scan />
				<button
					onClick={takeScan}
					aria-label="Scan button"
					className={cn('photo-trigger', {
						disabled: appStore?.result,
					})} />
				<video
					autoPlay
					playsInline
					{...appConf.window}
					ref={camPreview}
					className="cam-preview-" />
			</div>
			<Message {...appStore?.message} />
			<NotFound
				shown={appStore?.result?.statusDetailed === null}
				form={appState.manual ? undefined : ManualCodeForm}
				button={{
					children: appState.manual ? 'Check again' : 'Scan again',
					onClick: appState.manual ? clearResult : startScanning,
				}} />
			<Result
				result={appStore?.result}
				actionText={`${appState.manual ? 'Check' : 'Scan'} Additional Items`}
				onDone={appState.manual ? clearResult : startScanning} />
		</div>
	);

}
