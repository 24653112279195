import React from 'react';
import ReactDOM from 'react-dom';

import { initFireBase } from './service';
import { App } from './App';

import * as serviceWorkerRegistration from './service/serviceWorkerRegistration';
import reportWebVitals from './service/reportWebVitals';

initFireBase();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();

reportWebVitals();
