import firebase from 'firebase/app';
import 'firebase/firestore';

const {
	REACT_APP_appId: appId,
	REACT_APP_apiKey: apiKey,
	REACT_APP_projectId: projectId,
	REACT_APP_messagingSenderId: messagingSenderId,
	REACT_APP_measurementId: measurementId,
} = process.env;

export const initFireBase = async () => {

	const config = {
		appId,
		apiKey,
		projectId,
		messagingSenderId,
		measurementId,
		storageBucket: `${projectId}.appspot.com`,
		authDomain: `${projectId}.firebaseapp.com`,
		databaseURL: `https://${projectId}.firebaseio.com`,
	};

	firebase.initializeApp(config);

}
