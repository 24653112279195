import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import { Icon } from './';
import { ResultStatus, ResultType } from '../service';

const tsFormat = (d: number) => dayjs(d * 1000).format('MM/DD/YYYY');

const res: Record<ResultStatus, {
	head: string,
	text: string | ((date: number) => string),
}> = {
	approved: {
		head: 'Approved',
		text: (d) => `This item is Approved for Distribution.${d ? ` Expires on [${tsFormat(d)}]` : ''}`,
	},
	expired: {
		head: 'Expired',
		text: 'This item is now expired.',
	},
	expiring: {
		head: 'Expiring soon',
		text: (d) => `This item is Approved for Distribution, but expires within one month on [${tsFormat(d)}]`,
	},
};

export const Result: React.FC<{
	result?: ResultType,
	actionText?: string,
	onDone: (e: React.MouseEvent) => void,
}> = ({ result, actionText, onDone }) => {

	if (!result || !result.statusDetailed) {
		return null;
	}

	const stat = result.statusDetailed;

	const {
		head,
		text
	} = res[stat];

	return (
		<div className={cn('res-preview', {
			[stat]: result,
		})}>
			<div className="prev-data">
				<Icon name={`status-${stat}`} />
				<h1>{head}</h1>
				<p>{typeof text === 'function' ? text(result.expired_ts) : text}</p>
			</div>
			<div className="prev-action">
				<button
					onClick={onDone}
					className="add-scan">
					<span>{actionText}</span>
					<Icon name="plus" />
				</button>
			</div>
		</div>
	);

}
