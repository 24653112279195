import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';

import { checkManual, ResultType } from '../service';
import { Icon } from './Icon';

export interface ICodeForm {
	head?: string,
	onResult: (result: ResultType, number: string) => void,
	onFail: (e: any) => void
}

const CODE_PREFIX = 'PC-US-';

export const CodeForm: React.FC<ICodeForm> = ({
	head,
	onFail,
	onResult
}) => {

	const input = useRef<HTMLInputElement>(null);

	const [ number, setNumber ] = useState('');
	const [ loading, setLoading ] = useState(false);

	const onSubmit = useCallback(async (e: React.FormEvent) => {

		e.preventDefault();

		if (!input.current) {
			return;
		}

		if (!number || number === CODE_PREFIX) {
			return void input.current.focus();
		}

		try {

			setLoading(true);

			const result = await checkManual(number.trim());

			if (result.statusDetailed) {
				setNumber(CODE_PREFIX);
			}

			onResult(result, number);

		} catch (e) {

			onFail(e);

		} finally {

			setLoading(false);

		}

	}, [ number, input, onResult, onFail ]);

	return (
		<div className="code-form">
			{head && <h2>{head}</h2>}
			<form onSubmit={onSubmit}>
				<input
					ref={input}
					type="text"
					spellCheck="false"
					placeholder="PC-US-XXXXXX"
					aria-label="Manual code check input"
					value={number}
					onChange={({ target }) => setNumber(target.value.toUpperCase())}
					onFocus={({ target }) => !target.value && setNumber(CODE_PREFIX)} />
				<button
					className={cn({ loading })}
					aria-label="Manual code check button"
					disabled={loading}
					children={<Icon name="check" />} />
			</form>
		</div>
	);

}
